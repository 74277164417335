h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #b0805a; /* Brown */
}

body {
  font-family: Arial, sans-serif;
  background-color: #2b2d42; /* Dark teal */
  color: #b0805a; /* Brown */
  border: none;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.home-wrapper {
  flex: 1;
  background-color: #8d99ae; /* Teal */
  padding: 20px;
  overflow-x: hidden;
  max-width: 100%;
}

.sidebar {
  width: 50px;
  transition: width 0.3s ease-in-out;
  z-index: 1;
}

.sidebar-open {
  width: 200px;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  transition: width 0.3s ease-in-out;
  z-index: 2;
}

.top_section-open {
  width: 200px;
}

.bars {
  margin-left: 0;
  transition: margin-left 0.3s ease-in-out;
}

.bars-open {
  margin-left: 10px;
}

.link_text {
  display: none;
  color: #e9c46a; /* Gold */
}

.link_text-open {
  display: block;
  margin-left: 10px;
  font-weight: bold;
}

.App {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .container {
    flex-direction: column;
  }

  .sidebar,
  .top_section {
    width: 200px;
  }

  .bars {
    margin-left: 10px;
  }

  .link_text {
    display: block;
    margin-left: 10px;
    font-weight: bold;
  }
}
