.article {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #17151c;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #17151c;
}

.image-container {
  margin-bottom: 20px;
}

div.writing-wrapper {
  background-color: #545454;
}

.article-image {
  width: 100%;
  max-width: 120px;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.article-content {
  color: #8695f3;
  text-align: center;
}

.article-headline {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-subtext {
  margin-bottom: 10px;
}

.article-link {
  color: #ffb7b2;
  text-decoration: none;
  font-weight: bold;
}

.article-link:hover {
  color: #ff9c91;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .article {
    flex-direction: row;
    align-items: flex-start;
  }

  .image-container {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .article-content {
    text-align: left;
  }
}
