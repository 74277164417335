.herosection-left-wrapper {
  background-color: #1f2833;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #545454;
}

.herosection-left-container {
  max-width: 800px;
  margin: 0 auto;
}

.herosection-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.herosection-left-content {
  width: 100%;
  text-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
  color: #fefefe;
  font-family: "Permanent Marker", cursive;
  text-align: center;
}

.herosection-left-title {
  font-size: 42px;
  margin-bottom: 10px;
  color: #fefefe;
  text-decoration: underline;
  text-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
}

.herosection-left-subheading {
  font-size: 20px;
  margin-bottom: 10px;
  color: gold;
}

.herosection-left-paragraph {
  font-size: 16px;
  margin-bottom: 10px;
}

.herosection-left-image {
  width: 100%;
  text-align: center;
}

.herosection-left-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
}

h1,
.herosection-left-title {
  box-shadow: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .herosection-left {
    flex-direction: column;
  }
  
  .herosection-left-content,
  .herosection-left-image {
    width: 100%;
  }
}
