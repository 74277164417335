.signup-form-container {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #ffb7b2; /* Pastel Pink */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

form {
  background: #ffb7b2; /* Pastel Pink */
  max-width: 500px;
  width: 100%; /* To make it responsive */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #a2d2ff; /* Baby Blue */
  border-radius: 4px;
  margin-bottom: 10px;
  background: #17151c;
  color: #ffffff;
  font-size: 16px;
}

input[type="submit"] {
  background: #ffb7b2; /* Pastel Pink */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

input[type="submit"]:hover {
  background: #ff9c91;
}

button,
input[type="submit"] {
  font-family: Arial, sans-serif;
  background: #ffb7b2; /* Pastel Pink */
  color: #545454;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 3px 2px 4px 0px white;
}

button:before,
input[type="submit"]:before {
  content: "";
  background: rgba(147, 220, 236, 0.2);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(45deg) translate(-100%, -100%);
  transition: all 0.3s ease;
}
/* 
button:hover:before,
input[type="submit"]:hover:before {
  transform: rotate(45deg) translate(0, 0);
}

button:hover,
input[type="submit"]:hover {
  background: #ff9c91;
} */

.image-button {
  max-height: 150px;
  max-width: 150px;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .signup-form-container {
    top: auto;
    bottom: 20px;
    right: 20px;
  }
}
