@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  background-color: #000;
  color: #fff;
  font-family: 'Press Start 2P', cursive;
}

.wordle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wordle-heading {
  font-size: 28px;
  color: #ff00ff;
  margin-bottom: 20px;
}

.wordle-guesses {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.wordle-guess {
  padding: 10px;
  background-color: #ff00ff;
  color: #000;
  font-size: 18px;
  text-align: center;
}

.wordle-guess.correct {
  background-color: #00ff00;
}

.wordle-input {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.wordle-button {
  padding: 10px 20px;
  background-color: #ff00ff;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.wordle-button:hover {
  background-color: #00ff00;
}

/* Media Queries */
@media (max-width: 768px) {
  .wordle-heading {
    font-size: 24px;
  }

  .wordle-guesses {
    gap: 5px;
    margin-bottom: 10px;
  }

  .wordle-guess {
    padding: 8px;
    font-size: 16px;
  }

  .wordle-input {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .wordle-button {
    padding: 8px 16px;
    font-size: 16px;
  }
}
