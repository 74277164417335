@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");

.tabs {
  display: flex;
  justify-content: space-between;
  animation: animate__wobble 5s;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
  box-shadow: 2px 2px 4px #545454;
  font-family: "Roboto Condensed", sans-serif;
  border-radius: 10px;
  background-color: #1f2833;
  color: #fefefe;
}

.tab {
  width: 33.33%;
  text-align: center;
  animation: animate__shakeY 0.5s;
}

.tab-image {
  max-width: 100%;
  transition: transform 0.7s;
  border-radius: 50%;
}

.tab-image:hover {
  cursor: pointer;
  animation: animate__shakeY 0.5s;
}

.tab-text {
  color: #fefefe;
  font-size: 20px;
}

.Tabs-container {
  border: #545454 12px solid;
  border-radius: 50px;
}

h1 {
  animation: animate__wobble 5s;
  font-size: 28px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
  box-shadow: 2px 2px 4px #545454;
  font-family: "Roboto Condensed", sans-serif;
  border-radius: 10px;
  background-color: #1f2833;
  color: #fefefe;
}

/* Media Queries */
@media (max-width: 768px) {
  .tabs {
    flex-wrap: wrap;
    font-size: 16px;
  }

  .tab {
    width: 50%;
  }

  h1 {
    font-size: 24px;
  }
}
