@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

.herosection-right-wrapper {
  background-color: #1f2833;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #17151c;
  text-shadow: 2px 2px 4px #feb9c9;
  font-family: "Permanent Marker", cursive;
}

.herosection-right-container {
  max-width: 800px;
  margin: 0 auto;
}

.herosection-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.herosection-right-content {
  width: 100%;
  margin: 20px 0;
  color: #fefefe;
  text-shadow: 2px 2px 4px #feb9c9;
  font-family: "Permanent Marker", cursive;
  text-align: center;
}

.herosection-right-title {
  font-size: 42px;
  margin-bottom: 10px;
  color: #fefefe;
  text-decoration: underline;
  text-shadow: 2px 2px 4px #ffb7b2;
  box-shadow: none;
}

.herosection-right-subheading {
  font-size: 20px;
  margin-bottom: 10px;
  color: gold;
}

.herosection-right-paragraph {
  font-size: 16px;
  margin-bottom: 10px;
}

.herosection-right-image {
  width: 100%;
  text-align: center;
}

.herosection-right-image img {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
  box-shadow: 2px 2px 4px #ffb7b2;
}

@media (max-width: 768px) {
  .herosection-right-content,
  .herosection-right-image {
    width: 100%;
    margin: 20px 0;
  }
}
