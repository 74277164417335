.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: #ffb7b2;
  position: relative;
  height: 10vh;
  z-index: 9999;
}

.navbar-brand {
  font-size: 20px;
  font-weight: bold;
}

.navbar-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 50px;
}

.navbar-links li {
  margin-right: 10px;
}

.navbar-link {
  color: #ffffff;
  text-decoration: none;
}

.navbar-user {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.navbar-user-icon {
  font-size: 74px;
  cursor: pointer;
  border-radius: 25%;
  color: #545454;
  height: 5vh;
  width: 3vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border-right: 4vh;
}

.navbar-user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #17151c;
  box-shadow: 3px 4px 4px grey;
  padding: 10px;
  display: none;
  border-radius: 4px;
  z-index: 9999;
}

.navbar-user:hover .navbar-user-menu {
  display: block;
}

.navbar-user-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-user-link {
  text-decoration: none;
  display: block;
  padding: 5px 10px;
  color: #ffb7b2;
}

.navbar-user-link:hover {
  background-color: #f1f1f1;
}

.navbar-logo-image-trans {
  height: 75px;
  width: 75px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #545454;
  padding: 5px;
  box-shadow: 1px 1px 5px hotpink;
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar-links ul {
    font-size: 14px;
  }

  .navbar-user-icon {
    font-size: 20px;
  }
  
  .navbar-user-menu {
    top: 120%;
  }
}
