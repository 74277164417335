@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");


.heroContainer {
  color:white;
  padding: 20px;
  font-size: xx-large;
  font-family: "Roboto Condensed", sans-serif;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heroImageContainer {
  width: 100%;
}

.heroImageWrapper {
  display: flex;
  justify-content: center;
}

.heroImage {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

.heroContentContainer {
  width: 100%;
  text-align: center;
}

.heroContentWrapper {
  padding: 20px;
}

.heroTitle {
  font-size: 24px;
  margin-bottom: 10px;
  /* Additional styles to match */
  text-shadow: 2px 2px 4px rgba(254, 185, 201, 0.7);
}

.heroDescription {
  font-size: 16px;
  margin-bottom: 10px;
  /* Additional styles to match */
  text-shadow: .5px .5px .5px rgba(254, 185, 201, 0.7);
}

@media (min-width: 768px) {
  .heroSection {
    flex-direction: row;
  }

  .heroImageContainer {
    width: 50%;
  }

  .heroContentContainer {
    width: 50%;
  }
}
